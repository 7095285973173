<template>
  <div class="scanner">
    <div class="header-box">
      <span> Scan a QR Code to earn points! </span>
    </div>
    <QrcodeStream class="camera" @decode="onDecode" />
    <div v-if="invalidQRCode" class="invalid">
      <span> Invalid QR Code, Please try again </span>
    </div>
    <Logo24G class="logo" />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Logo24G from '@/assets/images/logo_24g.svg';

export default {
  name: 'Scanner',
  components: {
    QrcodeStream,
    Logo24G,
  },
  data() {
    return {
      result: '',
      invalidQRCode: false,
    };
  },
  methods: {
    onDecode(result) {
      // since the qrCode generate accepts a valid url instead of just an endpoint, i am storing the url in an array
      // and then splitting it and joining based on the intended functionality of the url
      const myArray = result.split('/');
      if (myArray[2] !== process.env.VUE_APP_DOMAIN) {
        this.invalidQRCode = true;
      } else {
        this.invalidQRCode = false;
        this.$router.push(`/target/${myArray[4]}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scanner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  margin-top: 4em;
  font-size: 16px;
  font-weight: bold;
  background-image: url(../assets/images/bg_nav@2x.png);
  background-position: center;
  background-size: cover;
}
.camera {
  width: 100%;
}
.invalid {
  margin-top: 1em;
  font-size: 18px;
  font-weight: bold;
  color: $red;
  text-transform: uppercase;
}
.logo {
  display: block;
  width: 2.5em;
  height: 1.2em;
  margin-top: 2em;
  margin-right: auto;
  margin-bottom: 1em;
  margin-left: auto;
}
</style>
